import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      display: "inline-block",
      position: "sticky",
      alignItems: "flex-start",
      backgroundColor: "#fff",
      top: 0,
    }}
  >
    <div
      style={{
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ fontWeight: 900, margin: "0 0 5px 0" }}>{siteTitle}</h1>
      <h3 style={{ marginBottom: 25, fontWeight: 300 }}>Photography</h3>

      <h6>
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
          href="https://www.instagram.com/neil_fenton/"
        >
          @neil_fenton
        </a>
      </h6>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
